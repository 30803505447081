import axios from '@/plugins/axios'

export default {

  async listar (query) {
    return await axios.get(`pessoas?${query}`)
  },

  async buscar (id) {
    return await axios.get(`pessoas/${id}`)
  },

  async inserir (dados) {
    return await axios.post('pessoas', dados)
  },

  async atualizar (id, dados) {
    return await axios.put(`pessoas/${id}`, dados)
  },

  // Endereços
  async listarEnderecos (id) {
    return await axios.get(`pessoas/${id}/enderecos`)
  },

  async inserirEndereco (id, dados) {
    return await axios.post(`pessoas/${id}/enderecos`, dados)
  },

  async atualizarEndereco (idPessoa, idEndereco, dados) {
    return await axios.put(`pessoas/${idPessoa}/enderecos/${idEndereco}`, dados)
  },

  async deletarEndereco (idPessoa, idEndereco) {
    return await axios.delete(`pessoas/${idPessoa}/enderecos/${idEndereco}`)
  },

  // E-mails
  async listarEmails (id) {
    return await axios.get(`pessoas/${id}/emails`)
  },

  async inserirEmail (dados) {
    return await axios.post('email-pessoa', dados)
  },

  async atualizarEmail (id, dados) {
    return await axios.put(`email-pessoa/${id}`, dados)
  },

  async deletarEmail (id) {
    return await axios.delete(`email-pessoa/${id}`)
  },

  // Telefones
  async listarTelefones (id) {
    return await axios.get(`pessoas/${id}/telefones`)
  },

  async inserirTelefone (dados) {
    return await axios.post('telefone-pessoa', dados)
  },

  async atualizarTelefones (id, dados) {
    return await axios.put(`telefone-pessoa/${id}`, dados)
  },

  async deletarTelefone (id) {
    return await axios.delete(`telefone-pessoa/${id}`)
  },

}
